@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Montserrat:wght@100,200,300,400,600,700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.7;
}
